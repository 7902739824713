<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12
      //- 表格
      b-card
        b-card-body
          .header-row.mt-0.mb-3.d-flex.justify-content-end.align-items-start
            b-button.width-md(variant="primary",:to="'/programsetting-edit/create'") ＋ 新增
          //- Table
          .table-responsive.mb-0
            b-table(
              ref="ProgramList"
              :items="getProgramList", 
              :fields="fields", 
              responsive, 
              :per-page="perPage", 
              :current-page="currentPage", 
            )

              template(v-slot:cell(index)="row")
                span {{ row.index + 1 }}

              template(v-slot:cell(on_tags)="row")
                b-badge.badge-outline-success(v-if="row.item.on_tags") 啟用
                b-badge.badge-outline-warning(v-else) 關閉

              //- 編輯
              template(v-slot:cell(editor)="row")
                b-button.table-btn(variant="primary" :to="'/programsetting-edit/' + row.item.id")
                  | 編輯
                b-button.ml-2(variant="danger" @click="deleteProgram(row.item.id)")
                  i.fe-trash-2 

          .row.mb-md-2
            .col
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  name: "ProgramList",
  data () {
    return {
      title: "節目設定",
      items: [
        {
          text: "影音節目管理",
          href: "/programsetting",
        },
        {
          text: "節目設定",
          active: true,
        }
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        { key: "index", label: "#", sortable: false, class: "center" },
        { key: "title", label: "節目名稱", sortable: false, class: "" },
        { key: "on_tags", label: "節目Tag", sortable: false, class: "center" },
        { key: "updated_at", label: "最後更新時間", sortable: false, class: "center" },
        { key: "editor", label: "操作", sortable: false, class: "center" },
      ]
    };
  },
  computed: {
  },
  mounted () {
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  methods: {
    reloadProgramList () {
      this.$refs.ProgramList.refresh()
    },
    // 獲得節目清單
    async getProgramList (_table, _callback) {
      let vm = this
      let search = { page: this.currentPage }
      await this.$root.apis.getProgramList(search,
        async function (_response) {
          let response = _response.body
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          if (typeof _callback === 'function') {
            await _callback(response.items)
          }
        })
    },

    deleteProgram(_id){
      var vm = this
      this.$root.common.confirmAction("刪除節目", "是否確定要刪除？", "刪除", "再想一下",
        function (_result) {
           vm.$root.apis.deleteProgram(_id, vm.deleteProgramSuccess)
        }
      )
    },
    async deleteProgramSuccess (_response) {
      await this.reloadProgramList()
    }
  }
}
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
.header-row
  display: flex
  justify-content: space-between
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
</style>
